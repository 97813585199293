import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0];

export const dictionary = {
		"/app": [12,[2]],
		"/app/accountGroups/[id]": [14,[2,3]],
		"/app/accountGroups/[id]/actors": [15,[2,3]],
		"/app/accountGroups/[id]/contracts": [16,[2,3]],
		"/app/accountGroups/[id]/interlocuteurs": [17,[2,3]],
		"/app/accounts/[id=integer]": [18,[2,4]],
		"/app/accounts/[id=integer]/actors": [19,[2,4]],
		"/app/accounts/[id=integer]/contracts": [20,[2,4]],
		"/app/accounts/[id=integer]/interlocuteurs": [21,[2,4]],
		"/app/competency": [22,[2]],
		"/app/competency/per-technician": [23,[2]],
		"/app/contrats/[id=integer]": [24,[2,5]],
		"/app/contrats/[id=integer]/factures": [25,[2,5]],
		"/app/contrats/[id=integer]/interlocuteurs": [26,[2,5]],
		"/app/contrats/[id=integer]/interventions": [27,[2,5]],
		"/app/contrats/[id=integer]/periodes": [28,[2,5]],
		"/app/dashboard": [29,[2,6]],
		"/app/dashboard/astreinte": [30,[2,6]],
		"/app/dashboard/enregistrement": [31,[2,6]],
		"/app/dashboard/installBase": [32,[2,6]],
		"/app/dashboard/interventions": [33,[2,6]],
		"/app/dashboard/quote": [34,[2,6]],
		"/app/dashboard/regions": [35,[2,6]],
		"/app/interventions": [36,[2]],
		"/app/invoices": [37,[2]],
		"/app/notifs": [38,[2,7]],
		"/app/notifs/deltas": [39,[2,7]],
		"/app/projectsMonPlanning": [41,[2]],
		"/app/projects": [40,[2]],
		"/app/quotes/[id]": [42,[2,8]],
		"/app/sites/[id=integer]": [43,[2,9]],
		"/app/sites/[id=integer]/acteurs": [44,[2,9]],
		"/app/sites/[id=integer]/contracts": [45,[2,9]],
		"/app/sites/[id=integer]/installBase": [46,[2,9]],
		"/app/sites/[id=integer]/projects": [47,[2,9]],
		"/app/sites/[id=integer]/quotes": [48,[2,9]],
		"/app/tmp": [49,[2]],
		"/app/[crud]": [13,[2]],
		"/auth/login": [50,[10]],
		"/design-system": [51,[11]],
		"/design-system/remult": [52,[11]],
		"/design-system/sql-optimizor": [53,[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';